<template>
    <div
        class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100 role-authorization"
    >
        <div class="ui-layout-content--full">
            <el-form ref="form" size="mini" :inline="true" label-width="80px">
                <el-form-item :label="'角色名称'">
                    <el-select
                        v-model="roleId"
                        filterable
                        clearable
                        :placeholder="'请选择'"
                        @change="SetCheckedKeys"
                    >
                        <el-option
                            v-for="item in roleList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :title="item.desc"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               :loading="isSubmiting"
                               @click="handleSave"
                               size="mini">{{'保存'}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-tree
            class="role-tree"
            v-loading="isSubmiting"
            :data="authList"
            node-key="id"
            show-checkbox
            ref="tree"
            default-expand-all
            :expand-on-click-node="false"
        >
            <span
                class="role-tree-node"
                slot-scope="{node, data}"
            >{{ data.entity.privilegeLanguages[0].name }}</span>
        </el-tree>
    </div>
</template>
<script>
export default {
    name: 'RoleAuthorization',
    data() {
        return {
            roleId: '',
            roleListData: [],
            currentRoleAuth: [],
            authTreeData: [],
            authList: [],
            isSubmiting: false
        };
    },
    computed: {
        roleList() {
            return this.roleListData.map((role) => {
                return {
                    label: role.role.roleLanguages[0].name,
                    value: role.role.id,
                    desc: role.role.roleLanguages[0].description
                };
            });
        }
    },
    methods: {
        SetCheckedKeys(val) {
            const currentRole = this._.find(this.roleListData, role => {
                return role.role.id === val;
            });
            if (currentRole) {
                this.currentRoleAuth = currentRole.privilegeIds;
            } else {
                this.currentRoleAuth = [];
            }
            const arrSet = this.currentRoleAuth;
            const arraySet = [];
            for (let i = 0; i < arrSet.length; i++) {
                this.deleteParentId(arrSet[i], this.authList, arraySet);
            }
            this.currentRoleAuth = arraySet;
            this.$refs.tree.setCheckedKeys(this.currentRoleAuth);
        },
        deleteParentId(value, data, arr) {
            for (let i = 0; i < data.length; i++) {
                const mod = data[i];
                if (mod.id === value) {
                    if (mod.children.length === 0) {
                        arr.push(mod.id);
                        return true;
                    }
                    return true;
                } else if (mod.children) {
                    if (mod.children.length !== 0) {
                        this.deleteParentId(value, mod.children, arr);
                    }
                }
            }
            return true;
        },
        async bindKeyValues() {
            const data = await Promise.all([this.$client.getRoleInfo(), this.$client.getPrivilegeTree()]);
            this.roleListData = data[0] || [];
            this.authList = data[1] || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
        },
        handleSave() {
            if (this.roleId === '') {
                this.$message({
                    message: '请选择角色',
                    type: 'warning'
                });
            } else {
                const arr = this.$refs.tree.getCheckedKeys();
                const array = [];
                for (let i = 0; i < arr.length; i++) {
                    this.fun(arr[i], this.authList, array);
                }
                const data = {
                    roleId: this.roleId,
                    privilegeIds: array
                };
                this.isSubmiting = true;
                this.$client.saveRoleAuth(data).then(() => {
                    this.$client.getRoleInfo().then(data => {
                        this.roleListData = data;
                    });
                    const currentRole = this.roleListData.filter(i => i.role.id === data.roleId);
                    currentRole.privilegeIds = [...data.privilegeIds];
                    this.isSubmiting = false;
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                });
            }
        },
        noRepeat(arr, value) {
            //id去重
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === value) {
                    return false;
                }
            }
            return true;
        },
        fun(value, data, arr) {
            //通过查询父节点下是否右子节点 将半选状态的父节点加入数组中
            for (let i = 0; i < data.length; i++) {
                const mod = data[i];
                if (mod.id === value) {
                    if (this.noRepeat(arr, mod.id)) {
                        arr.push(mod.id);
                    }
                    return true;
                } else if (mod.children) {
                    const bool = this.fun(value, mod.children, arr);
                    if (bool) {
                        if (this.noRepeat(arr, mod.id)) {
                            arr.push(mod.id);
                        }
                        return true;
                    }
                }
            }
            return false;
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>

<style lang="less" scoped>
.role-authorization {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .role-tree {
        flex: 1;
        overflow: auto;
        &-node {
            display: flex;
            align-items: flex-start;
            font-size: 14px;
            padding-right: 8px;
            // &__label{
            // width: 400px;
            //}
        }
    }
}
</style>
